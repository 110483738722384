.wrapper-bloader{
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%, -50%); 
  white-space: nowrap;
}
.circle-bload{
  display: inline-block;
  width: 17px;
  height: 17px;
  background-color: #fff;
  border-radius: 50%;
  animation: loading 1.3s cubic-bezier(.8, .5, .2, 1.4) infinite;
  transform-origin: bottom center;
  position: relative;
  margin-left: 5px;
}
@keyframes loading{
  0%{
    transform: translateY(0px);
    background-color: #fff;
  }
  50%{
    transform: translateY(-60px);
    background-color: #0265F4;
  }
  100%{
    transform: translateY(0px);
    background-color: #fff;
  }
}
.circle-bload-1{
  animation-delay: 0.06s;
}
.circle-bload-2{
  animation-delay: 0.12s;
}
.circle-bload-3{
  animation-delay: 0.18s;
}
.circle-bload-4{
  animation-delay: 0.24s;
}
.circle-bload-5{
  animation-delay: 0.30s;
}
.circle-bload-6{
  animation-delay: 0.36s;
}
.circle-bload-7{
  animation-delay: 0.42s;
}
.circle-bload-8{
  animation-delay: 0.48s;
}